<template>
  <div>
    <loading-overlay :active="isLoading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="$t('label.nuevo')+' '+$t('label.CancelationReason')"
      color="dark"
      size="lg"
      :close-on-backdrop="false"
      @update:show="toggle(false)"
      :show.sync="modalActive"
      class="modal-extended"
    >
      <form @submit.stop.prevent="submit" class="company-form">
        <CRow>
          <CCol sm="12">
            <CCard class="card-simple">
              <CCardBody>
                <CRow>
                  <CCol sm="12"> 
                        <CSelect
                          v-model.trim="$v.cancelacion.movimientoId.$model"
                          :is-valid="hasError($v.cancelacion.movimientoId)"
                          :invalid-feedback="errorMessage($v.cancelacion.movimientoId)"
                          :label="$t('label.movement')"
                          addLabelClasses="required text-right"
                          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                          :options="movimientosFormatted"
                          class="mt-1"
                          @change="getTransacciones"
                        />
                  </CCol>
                  <CCol sm="12"> 
                        <CSelect
                          v-model.trim="$v.cancelacion.transactionId.$model"
                          :is-valid="hasError($v.cancelacion.transactionId)"
                          :invalid-feedback="errorMessage($v.cancelacion.transactionId)"
                          :label="$t('label.transaction')"
                          addLabelClasses=" required text-right"
                          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                          :options="transaccionesFormatted"
                          class="mt-1"
                        />
                  </CCol>
                  <CCol sm="12"> 
                    <CInput
                      v-model.trim="$v.cancelacion.CancellationReasonEs.$model"
                      v-uppercase
                      :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                      :invalid-feedback="errorMessage($v.cancelacion.CancellationReasonEs)"
                      :is-valid="hasError($v.cancelacion.CancellationReasonEs)"
                      :label="$t('label.CancellationReason')+' (ES)'"
                      :placeholder="$t('label.CancellationReasonName')+' (ES)'"
                      addLabelClasses="required text-right"
                      maxlength="100"
                    >
                    </CInput>
                  </CCol>
                  <CCol sm="12"> 
                    <CInput
                      v-model.trim="$v.cancelacion.CancellationReasonEn.$model"
                      v-uppercase
                      :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                      :invalid-feedback="errorMessage($v.cancelacion.CancellationReasonEn)"
                      :is-valid="hasError($v.cancelacion.CancellationReasonEn)"
                      :label="$t('label.CancellationReason')+' (EN)'"
                      :placeholder="$t('label.CancellationReasonName')+' (EN)'"
                      addLabelClasses="required text-right"
                      maxlength="100"
                    >
                    </CInput>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>         
      </form>
      <template #footer>
        <CButton
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="submit"
        >
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </CButton>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>

<script>
import CancelValidation from '@/_validations/estiba/CancelFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import { MovimientoMixin } from '@/_mixins/estiba';

//Data
function data() {
  return {
    modalActive: this.modal,
    isLoading: false,
    isSubmit: false,
    cancelacion: {
      movimientoId: '',
      transactionId:'',
      CancellationReasonEn:'',
      CancellationReasonEs:'',
    },
    movimientos: [],
    transacciones: [],
  }
}

//Methods
function submit() {
  try {
  this.isSubmit = true;
  this.isLoading = true;

  this.$v.cancelacion.$touch();
    if (this.$v.cancelacion.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }

    let MovStowageReasonJson = {
      MovStowageReasonNameEs: this.cancelacion.CancellationReasonEs,
      MovStowageReasonName: this.cancelacion.CancellationReasonEn,
      TransacStowageId: this.cancelacion.transactionId,
    };

    this.$http.post("MovStowageReason-insert", MovStowageReasonJson, { root: 'MovStowageReasonJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
       this.isSubmit = false;
       this.isLoading = false;
    });
  }  catch (e) {
    this.isLoading = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();

  this.modalActive = newVal;
}
function resetForm() {
  this.cancelacion.movimientoId='';
  this.cancelacion.transactionId='';
  this.cancelacion.CancellationReasonEn='';
  this.cancelacion.CancellationReasonEs='';
  this.movimientos= [];
  this.transacciones= [];

  this.$v.$reset();
}


function getMovimientos() {
  this.isLoading = true;
  this.$http.get("MovStowage-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.movimientos = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}


function getTransacciones() {
  if (this.cancelacion.movimientoId != '') {
    this.isLoading = true;
    this.cancelacion.transactionId = '';
    this.$http.get("TransacStowage-list", { MovStowageId: this.cancelacion.movimientoId, Filter: 'ACTIVO' })
    .then(response => {
      this.transacciones = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isLoading = false;
    });
  }else{
    this.cancelacion.transactionId = '';
    this.transacciones = [];
  }
  
}

//Computeds:

function movimientosFormatted(){
  if(this.movimientos.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = this.$i18n.locale;
    this.movimientos.map(function(e){
      chart.push({
        value: e.MovStowageId,
        label: _lang=='en' ? e.MovStowageName : e.MovStowageNameEs,
      })    
    })
    return chart;
  }
}

function transaccionesFormatted(){
  if(this.transacciones.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = this.$i18n.locale;
    this.transacciones.map(function(e){
      chart.push({
         value: e.TransacStowageId,
         label: _lang=='en' ? e.TransacStowageName : e.TransacStowageNameEs,
      })    
    })
    return chart;
  }
}

export default {
  name: 'add-cancelacion-modal',
  mixins: [
    MovimientoMixin
  ],
  props: {
    modal: Boolean
  },
  data,
  validations: CancelValidation,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    getTransacciones,
    getMovimientos
  },
  computed: {
    movimientosFormatted,
    transaccionesFormatted,
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
      if (val) {
        this.getMovimientos();
      }
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    }
  }
}
</script>
<style lang="scss">
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
.modal-content-mov {
  .modal-content {
    width: 70% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>